<template lang="">
  <div
    style="
      display: flex;
      flex: 1;
      height: 70vh;
      width: 100%;
      align-items: center;
      justify-content: center;
    "
  >
    <b-spinner  variant="primary"/>
  </div>
</template>
<script>
import {BSpinner} from 'bootstrap-vue';

export default {
  name: 'Loader',
  data() {
    return {
      color: '#3AB982',
      height: '35px',
      width: '4px',
      margin: '2px',
      radius: '2px',
      size: '35px',
    };
  },
  components: { BSpinner },
};
</script>
<style lang=""></style>
