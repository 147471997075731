import axios from '@axios';

export const postCall = async (url, data = {}) => {
  return await axios.post(url, data);
};

export const getCall = async (url) => {
  return await axios.get(url);
};

export const putCall = async (url, body = {}) => {
  return await axios.put(url, body);
};

export const deleteCall = async (url) => {
  return await axios.delete(url);
};

// export default api;
