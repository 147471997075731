<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
          <b-col cols="6" md="6">
            <div class="d-flex align-items-center justify-content-end">
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card >
      <b-table
      :busy="loading"
      style="text-align: center;"
        striped
        show-empty
        hover
        :items="carts"
        :fields="fields"
      >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <loader />
        </div>
      </template>
      <template #cell(price)="data">
      <b-form-text >{{
      data.item.cartTotal
      }}</b-form-text>
      </template>
      <template #cell(orderStatus)="data">
          <b-badge
          pill
          style="min-width: 140px; max-width: 180px;"
          :variant="`light-${returnOrder(data.item.orderStatus)}`">
          {{ orderStatusDict[data.item.orderStatus] }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-button variant=".btn-flat-dark" @click="showModal(data.item.id)">
            <external-link-icon
              size="1.5x"
              class="custom-class text-primary"
            ></external-link-icon>
          </b-button> </template
      ></b-table>
      <b-pagination
        style="align-items: flex-end; flex: 1; justify-content: end"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-2"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>

    <b-modal 
    hide-footer
    centered 
    size="lg" 
    ref="cart-detail">
      <b-table
        :busy="loader"
        striped
        style="text-align: center;"
        hover
        :items="cartItem"
        :fields="cartFields"
        showEmpty
      >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </template>
        <template #cell(productImage)="data">
          <img
          style="height:80px;width:80px;align-self:center"
          :src=data.item.imageUrl
          />
          </template>       
      </b-table>
      <div v-if="cartItem && cartItem.length" class="d-flex justify-content-end">
          <b-button
            class="mr-1"
            @click="convertCartToOrder"
            variant="primary"
            
                >
                <span class="text-nowrap">Convert to order</span>
                </b-button>
                <b-button style="margin-left: 2px;" @click="emptyCart" variant="danger">
                  <span class="text-nowrap">Empty Cart</span>
                </b-button>
              </div>
    </b-modal>

  </div>
</template>

<script>
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BModal,
  BSpinner,
  BButton,
  BBadge,
  BCard,
  BFormText,
  BPagination,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import useCartList from './useCartList';
import { ref } from '@vue/composition-api';
import {
  Trash2Icon,
  EditIcon,
  EyeOffIcon,
  ExternalLinkIcon,
} from 'vue-feather-icons';
import { deleteCall, getCall, putCall } from '../Service';
import Loader from '../components/Loader.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    BTable,
    BModal,
    BCol,
    BSpinner,
    BFormText,
    BLink,
    BAvatar,
    BMedia,BPagination,
    BBadge,
    BRow,
    vSelect,
    BFormInput,
    BButton,
    BCard,
    BFormCheckbox,
    BImg,
    Trash2Icon,
    EditIcon,
    EyeOffIcon,
    ExternalLinkIcon,
    Loader,
},
  data() {
    return {
      skip:0,
      rows:0,
      searchQuery:'',
      pageSeries: [],
      perPage: 10,
      currentPage: 1,
      carts: [],
      loader:false,
      cartItem: [],
      cartId: null,
      itemsShown: false,
      fields: [
        // 'id',
        'orderStatus',
        // 'trackingNumber',
        'customerName',
        'price',
        'actions',
      ],
      orderStatusDict: {
        0: 'AWAITING_PROCESSING',
        1: 'PROCESSING',
        10: 'READY_TO_SHIP',
        20: 'SHIPPED',
        100: 'DELIVERED',
        200: 'WILL_NOT_DELIVER',
        400: 'RETURNED',
        2:'OUT_FOR_DELIVERY'
      },
      cartFields: [
        'productImage',
        'productid',
        'quantity',
        'sku',
        'weight',
        // 'select',
        'actions',
      ],
      loading: false,
    };
  },
  created() {},
  updated() {},
  async mounted() {
    await this.fetchAllCarts(0,this.perPage,'');
  },
  watch:{
    currentPage:function(newVal,old){
        const series = this.pageSeries.find((i) => i.p == newVal);
        this.selectedRecords = series.s;
        this.fetchAllCarts(series.s, this.perPage, "")
    },
    rows: function () {
      let pages = this.rows / 10;
      if (pages != 0) {
        if (this.rows > 10) {
          pages = pages + 1;
        }
      }
      this.pageSeries = [];
      this.skip = 0;
      for (let i = 1; i <= pages; i++) {
        this.pageSeries.push({ s: this.skip, p: i });
        this.skip = this.skip + 10;
      }
    },
    searchQuery:function(newVal){
      this.fetchAllCarts(0,this.perPage,newVal ? newVal : '')
    }
  },
  methods: {
    async showModal(id) {
      this.$refs['cart-detail'].show()
      this.cartId = id;
        try{
          this.loader=true
          const { data } = await getCall(`admin/store/cart/${id}`);
        this.cartItem = data.cartItem;
        this.loader=false
        }catch(err){
          this.loader=false
        }
      },
    async fetchAllCarts(skip, take, search) {
      this.loading = true;
      try {
        const { data } = await getCall(`admin/store/carts/all?skip=${skip}&take=${take}&keyword=${search}`);
        this.carts = data.cartItems;
        this.rows = data.total
      } catch (error) {
        alert(`Error: ${error}`);
      } finally {
        this.loading = false;
      }
    }, 
    emptyCart() {
      Swal.fire({
        title: 'Do you want to empty cart?',
        showCancelButton: true,
        confirmButtonText: 'Empty',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteCall('admin/store/carts/empty-cart');
          await this.fetchAllCarts(0,this.perPage,'');
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Cart Deleted Successfully`,
              icon: 'CoffeeIcon',
              variant: 'danger',
            },
          });
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      });
    },
    convertCartToOrder(id) {
      Swal.fire({
        title: 'Do you want to convert cart to order?',
        showCancelButton: true,
        confirmButtonText: 'Convert cart',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await putCall(`admin/store/order/cart-to-order/${this.cartId}`
          );
        this.$refs['cart-detail'].hide()
          this.fetchAllCarts(0,this.perPage,'');
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Cart Converted to Order Successfully`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          });
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      });
    },
  },
 
  setup() {
    const { columns,returnOrder } = useCartList();
    const isVisible = ref(false);
    return {
      columns,
      returnOrder,
      isVisible,
    };
  },
};
</script>

<style>
.noProducts {
  text-align: center;
}
</style>
