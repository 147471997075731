export default function useCartList() {
  const columns = [
    { key: 'productName', sortable: true },
    { key: 'price', sortable: true },
    { key: 'stock', sortable: true },
    { key: 'action', sortable: false },
  ];

  function returnOrder(status) {
    if (status == 200) {
      return 'danger'
    } else if (status == 1) {
      return 'warning'
    }
    else if (status == 100) {
      return 'success'
    }
    else {
      return 'primary'
    }
  }

  

  return {
    columns,
    returnOrder
  };
}
